<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="breadcrumbs">
        <span class="system">
          Video Apps Engine / Navigation /
        </span>
        <span v-if="!isEdit">
          Add Navigation Item
        </span>
        <span
          v-else
        >
          Edit {{ nav.heading }} Navigation Item
        </span>
      </div>
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="title">
        {{ isEdit ? 'Navigation Item Editor' : 'Add Navigation Item' }}
      </div>
      <div>
        <Tabs
          :smallStyle="true"
          :isLong="true"
        >
          <Tab
            name="General"
            :selected="true"
            :hasError="generalError"
            :className="generalError ? 'error-tab' : 'tab'"
          >
            <div>
              <MaterialSelect
                v-model="formData.pageTarget"
                v-bind="formFields.pageTarget"
                :options="pageTargetOprions"
                :withEmptyField="false"
              />
              <MaterialSelect
                v-model="formData.badge"
                v-bind="formFields.badge"
                :options="badgeOptions"
                :withEmptyField="false"
              />
              <div class="toggle-wrapper dynamic">
                <div class="toggle-title">
                  Dynamic Title Source
                </div>
                <ToggleButton
                  v-model="formData.isDynamicTitle"
                  color="#2591CF"
                  :sync="true"
                  :labels="false"
                />
              </div>
              <div
                v-if="!formData.isDynamicTitle"
                class="localization-wrapper"
              >
                <div class="label">
                  <MaterialInput
                    v-model="formData.rowHeading"
                    v-bind="formFields.rowHeading"
                  />
                </div>
                <div v-if="enabledLanguages.length">
                  <Button
                    :title="'Localization'"
                    :classType="'primary'"
                    :srcSvg="iconFolderLocalization"
                    :svgClass="'folder-localization-icon'"
                    :eventFunction="toLocalization"
                    :isReverseIcon="true"
                    :disabled="!isEdit"
                  />
                </div>
              </div>
              <div
                v-if="enabledLanguages.length
                  && !formData.isDynamicTitle
                  && !isEdit
                  && enabledLanguages.length"
                class="localization-warning-wrapper"
              >
                <inline-svg
                  :src="iconWarning"
                  class="svg-icon"
                >
                </inline-svg>
                <span class="localization-warning">
                  Localization will be available after the Navigation Item is saved
                </span>
              </div>
              <div class="nav-icon-block">
                <div
                  :class="['nav-icon-wrapper',
                           isNavIconsError ? 'nav-icon-wrapper-error' : '']"
                  @click="onOpenIconsModal"
                >
                  <inline-svg
                    v-if="!formData.navIcon"
                    :src="iconAddNavIcon"
                    class="svg-icon"
                  >
                  </inline-svg>
                  <img
                    v-else
                    class="nav-icon-img"
                    :src="formData.navIcon.url"
                    alt="image"
                  />
                </div>
                <div style="margin-left: 20px">
                  Navigation Icon
                </div>
              </div>
              <div
                v-if="isNavIconsError"
                class="nav-icon-error-label"
              >
                This field is required
              </div>
            </div>
          </Tab>
          <Tab
            v-if="getCurrentAccount.videoAppsEnginePermissionEnabled"
            name="Access Permissions"
            :selected="false"
          >
            <MediaTagManagement
              v-if="permissionsGroupOptions.length"
              :key="`permissionGroups${formData.id + 1}`"
              :selectedValue="formData.permissionGroups"
              :label="getCurrentAccount.authenticationProvider
                && getCurrentAccount.authenticationProvider.type === 'okta'
                ? 'Select Okta User Group'
                : 'Select OneLogin User Roles'"
              :tagPlaceholder="getCurrentAccount.authenticationProvider
                && getCurrentAccount.authenticationProvider.type === 'okta'
                ? 'Search Okta User Group'
                : 'Search OneLogin User Roles'"
              :searchable="true"
              :onChange="onTagIncludedTypesChange"
              :customOptions="permissionsGroupOptions"
              :isAudio="true"
              :isJustSearch="true"
              :max="20000"
            />
          </Tab>
        </Tabs>
      </div>
      <NavigationLocalization
        v-if="enabledLanguages.length"
        v-model="isOpen"
        :languages="enabledLanguages"
        :modal="isOpen"
        :isPageTranslate="true"
        :onClose="onClose"
      />
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :title="'Delete Navigation Item'"
        :onAccept="onRemove"
        :onClose="onCloseRemove"
      />
      <NavigationIconsModal
        ref="modal"
        v-model="isOpenIconsModal"
        :modal="isOpenIconsModal"
        :title="'Select Navigation Item Icon'"
        :onAccept="onAddNavIcons"
        :onClose="onCloseNavIcons"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import MaterialInput from '../../inputs/MaterialInput.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import Button from '../../common/Button.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import NotificationModal from '../../NotificationModal.vue';
import NavigationLocalization from '../../localization/NavigationLocalization.vue';
import Tabs from '../../common/Tabs/Tabs.vue';
import Tab from '../../common/Tabs/Tab.vue';
import BaseForm from '../BaseForm.vue';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';
import iconAddNavIcon from '../../../assets/icons/icon-nav-add-icon.svg';
import MediaTagManagement from '../../media/MediaTagManagement.vue';

import {
  CREATE_NAVIGATION_REQUEST,
  EDIT_NAVIGATION_REQUEST,
  GET_CMS_PAGES_FOR_NAVIGATION_REQUEST,
  GET_NAVIGATION_LIST_REQUEST,
  GET_NAVIGATION_REQUEST,
  GET_PERMISSION_GROUP,
  REMOVE_NAVIGATION_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import CONFIG from '../../../constants/config';
import { GET_LANGUAGES_REQUEST } from '../../../store/actions/localization/localizationActions';
import NavigationIconsModal from '../../common/NavigationIconsModal.vue';

export default {
  name: 'NavigationForm',
  components: {
    NavigationIconsModal,
    NavigationLocalization,
    MediaTagManagement,
    MaterialSelect,
    NotificationModal,
    Button,
    MaterialInput,
    Tabs,
    Tab,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    formPrefix: {
      type: String,
      default: 'navigationForm',
    },
  },
  data() {
    return {
      isOpenIconsModal: false,
      generalError: null,
      permissionsGroupOptions: [],
      isOpen: false,
      iconFolderLocalization,
      iconAddNavIcon,
      enabledLanguages: [],
      isNavIconsError: false,
      tabErrors: {
        general: false,
      },
      badgeOptions: [
        {
          code: 'none',
          name: 'None',
        },
        {
          code: 'live_badge',
          name: 'Live Badge',
        },
        {
          code: 'live_badge_minimal',
          name: 'Live Badge (minimal)',
        },
      ],
      formData: {
        id: 0,
        pageTarget: '',
        badge: 'none',
        isDynamicTitle: true,
        rowHeading: '',
        sortOrder: 0,
        permissionGroups: [],
        navIcon: null,
      },
      formFields: {
        pageTarget: {
          type: 'text',
          name: 'pageTarget',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        badge: {
          type: 'text',
          name: 'badge',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        rowHeading: {
          type: 'text',
          name: 'rowHeading',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
      },
      nav: {},
      isReady: false,
      isHasId: true,
      isModalOpen: false,
      iconWarning,
      bodyMessage: 'Are you sure you want to delete this Navigation Item?',
      errorMapping: {
        pageTarget: [
          `${this.formPrefix}.targetPage`,
        ],
        folderId: [
          `${this.formPrefix}.targetMediaFolder`,
        ],
        poster: [
          `${this.formPrefix}.bannerImage`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_NAVIGATION_REQUEST : CREATE_NAVIGATION_REQUEST,
      successMessage: this.isEdit
        ? 'The Navigation Item has been successfully updated'
        : 'The Navigation Item has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      navigations: (state) => state.cms.navigationList,
      pageTargetOprions: (state) => state.cms.navPages.map((item) => ({
        code: item.id,
        name: item.name,
      })),
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
  },
  created() {
    if (!this.isEdit && this.getCurrentAccount.videoAppsEnginePermissionEnabled) {
      this.$store.dispatch(GET_PERMISSION_GROUP, { accountId: this.getCurrentAccountId })
        .then((res) => {
          this.permissionsGroupOptions = res.data.map((item) => ({
            name: `${item.name} (${item.externalId})`,
            code: item.externalId,
          }));
        });
    }

    const params = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_CMS_PAGES_FOR_NAVIGATION_REQUEST, params);

    const paramsList = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_NAVIGATION_LIST_REQUEST, paramsList);

    const paramsLang = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, paramsLang).then((res) => {
      this.enabledLanguages = res.data;
    });

    if (this.isEdit && this.$route.params.id) {
      const params = {
        accountId: this.getCurrentAccountId,
        navigationId: this.$route.params.id,
      };

      this.$store.dispatch(GET_NAVIGATION_REQUEST, params)
        .then((res) => this.initFormData(res));
    }
  },
  methods: {
    initFormData(item) {
      this.nav = item;
      this.formData.id = item.id;
      this.formData.pageTarget = item.targetPage.id;
      this.formData.isDynamicTitle = item.sourceTitle;
      this.formData.rowHeading = item.heading;
      this.formData.sortOrder = item.sortOrder;
      this.formData.badge = item.liveBadgeType;
      this.formData.navIcon = item.icon;

      if (this.getCurrentAccount.videoAppsEnginePermissionEnabled) {
        this.$store.dispatch(GET_PERMISSION_GROUP, { accountId: this.getCurrentAccountId })
          .then((res) => {
            this.permissionsGroupOptions = res.data.map((item) => ({
              name: `${item.name} (${item.externalId})`,
              code: item.externalId,
            }));

            this.formData.permissionGroups = item.permissionGroups.map((item) => ({
              id: item.externalId,
              name: this.permissionsGroupOptions.find((perm) => perm.code === item.externalId).name,
            }));
          });
      }
    },
    onTagIncludedTypesChange(value) {
      this.formData.permissionGroups = value;
    },
    onOpenModal() {
      this.isModalOpen = true;
    },
    onOpenIconsModal() {
      this.isOpenIconsModal = true;
    },
    onCloseRemove() {
      this.isModalOpen = false;
    },
    onCloseNavIcons() {
      this.isOpenIconsModal = false;
    },
    toLocalization() {
      this.isOpen = true;
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord) {
        this.isRecorderToggleOpen = true;
      }
    },
    backTo() {
      this.$router.push('/cms/navigation');
    },
    onClose() {
      this.isOpen = false;
    },
    onCloseWarning() {
      this.isOpenWarning = false;
      this.formData.eventGroup = this.cmsPage.standaloneEventGroup.id;
    },
    // eslint-disable-next-line consistent-return
    handleChangeEvent() {
      if (this.cmsPage.standaloneEventGroup && !this.cmsPage.standaloneEventGroup.eventsCompleted) {
        this.isOpenWarning = true;
      }
    },
    continueEditing() {
      this.isOpenWarning = false;
    },
    toggleEnabled() {
      this.formData.distribution = !this.formData.distribution;
    },
    hasCustomFieldErrors(fields, errors) {
      return fields.some((item) => {
        const fieldName = this.getFieldName(item.field);
        return errors[fieldName] && errors[fieldName].length;
      });
    },
    onAddNavIcons(data) {
      this.isNavIconsError = false;
      this.formData.navIcon = data;
    },
    onRemove() {
      const params = {
        accountId: this.getCurrentAccountId,
        navigationId: this.$route.params.id,
      };

      this.$store.dispatch(REMOVE_NAVIGATION_REQUEST, params)
        .then(() => {
          setTimeout(() => {
            this.backTo();
          }, CONFIG.routing.redirectFromAddAcc);
          this.$toasted.global.success({
            message: 'The Navigation Item has been successfully deleted',
          });
        })
        .catch((err) => {
          if (err.status === CONFIG.statuses.failedStatus) {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.commonServerError,
            });
          }
        });
    },
    getRequestData() {
      const data = {
        heading: this.formData.rowHeading,
        sourceTitle: this.formData.isDynamicTitle,
        targetPage: this.formData.pageTarget,
        liveBadgeType: this.formData.badge,
        sortOrder: this.navigations.length ? this.navigations.length + 1 : 1,
      };

      if (this.formData.isDynamicTitle) {
        delete data.heading;
      }

      if (this.formData.navIcon && this.formData.navIcon.id) {
        data.icon = this.formData.navIcon.id;
      }

      if (this.isEdit) {
        data.sortOrder = this.formData.sortOrder;
      }

      if (this.formData.permissionGroups.length) {
        data.permissionGroups = this.formData.permissionGroups.map((item) => ({
          externalId: item,
        }));
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        navigationId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.backTo();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      this.posterError = error.form_errors.children
        && error.form_errors.children.bannerImage
        && error.form_errors.children.bannerImage.errors;

      const navIconsError = error.form_errors.children
        && error.form_errors.children.icon
        && error.form_errors.children.icon.errors;

      if (navIconsError) {
        this.isNavIconsError = true;
      }

      const generalError = (error.form_errors.children
        && error.form_errors.children.targetPage
          && error.form_errors.children.targetPage.errors)
        || navIconsError;
      if (generalError) {
        this.generalError = generalError;
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        if (error.form_errors.errors[0].error === 'LIMIT_ITEMS_ERROR') {
          this.$toasted.global.error({
            message: 'Navigation is currently limited to 5 items',
          });
          return;
        }

        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-bottom: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.system {
  color: #4c5057;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.localization-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.label {
  width: 400px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.nav-icon-wrapper {
  width: 90px;
  height: 90px;
  border-radius: 12px;
  border: 1px dashed #4C5057;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav-icon-block {
  align-items: center;
  display: flex;
}

.nav-icon-img {
  width: 50%;
  height: 50%;
  object-fit: contain;
}

.nav-icon-wrapper-error {
  border-color: #fb3951;
}

.nav-icon-error-label {
  color: #fb3951;
  font-size: 12px;
  margin-top: 5px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
