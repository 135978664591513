export const ADD_ACCOUNT_REQUEST = 'ADD_ACCOUNT_REQUEST';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_ERROR = 'ADD_ACCOUNT_ERROR';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_ERROR = 'GET_STATE_ERROR';

export const EDIT_ACCOUNT_STATUS_REQUEST = 'EDIT_ACCOUNT_STATUS_REQUEST';
export const EDIT_ACCOUNT_STATUS_SUCCESS = 'EDIT_ACCOUNT_STATUS_SUCCESS';
export const EDIT_ACCOUNT_STATUS_ERROR = 'EDIT_ACCOUNT_STATUS_ERROR';

export const CREATE_AKAMAI_APP_REQUEST = 'CREATE_AKAMAI_APP_REQUEST';
export const CREATE_AKAMAI_APP_SUCCESS = 'CREATE_AKAMAI_APP_SUCCESS';
export const CREATE_AKAMAI_APP_ERROR = 'CREATE_AKAMAI_APP_ERROR';

export const SYNC_AKAMAI_APP_REQUEST = 'SYNC_AKAMAI_APP_REQUEST';
export const SYNC_AKAMAI_APP_SUCCESS = 'SYNC_AKAMAI_APP_SUCCESS';
export const SYNC_AKAMAI_APP_ERROR = 'SYNC_AKAMAI_APP_ERROR';

export const GET_ALL_ACCOUNTS_REQUEST = 'GET_ALL_ACCOUNTS_REQUEST';
export const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS';
export const GET_ALL_ACCOUNTS_ERROR = 'GET_ALL_ACCOUNTS_ERROR';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const EDIT_ACCOUNT_REQUEST = 'EDIT_ACCOUNT_REQUEST';
export const EDIT_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const EDIT_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';

export const GET_SUBSCRIPTION_PLAN_REQUEST = 'GET_SUBSCRIPTION_PLAN_REQUEST';
export const GET_SUBSCRIPTION_PLAN_SUCCESS = 'GET_SUBSCRIPTION_PLAN_SUCCESS';
export const GET_SUBSCRIPTION_PLAN_ERROR = 'GET_SUBSCRIPTION_PLAN_ERROR';

export const GET_SUBSCRIPTION_TYPE_REQUEST = 'GET_SUBSCRIPTION_TYPE_REQUEST';
export const GET_SUBSCRIPTION_TYPE_SUCCESS = 'GET_SUBSCRIPTION_TYPE_SUCCESS';
export const GET_SUBSCRIPTION_TYPE_ERROR = 'GET_SUBSCRIPTION_TYPE_ERROR';

export const GET_ACCOUNT_SETTINGS_REQUEST = 'GET_ACCOUNT_SETTINGS_REQUEST';
export const GET_ACCOUNT_SETTINGS_SUCCESS = 'GET_ACCOUNT_SETTINGS_SUCCESS';
export const GET_ACCOUNT_SETTINGS_ERROR = 'GET_ACCOUNT_SETTINGS_ERROR';

export const EDIT_ACCOUNT_SETTINGS_REQUEST = 'EDIT_ACCOUNT_SETTINGS_REQUEST';
export const EDIT_ACCOUNT_SETTINGS_SUCCESS = 'EDIT_ACCOUNT_SETTINGS_SUCCESS';
export const EDIT_ACCOUNT_SETTINGS_ERROR = 'EDIT_ACCOUNT_SETTINGS_ERROR';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const GET_CONTRIBUTOR_ACCOUNTS_ADD_REQUEST = 'GET_CONTRIBUTOR_ACCOUNTS_ADD_REQUEST';
export const GET_CONTRIBUTOR_ACCOUNTS_ADD_SUCCESS = 'GET_CONTRIBUTOR_ACCOUNTS_ADD_SUCCESS';
export const GET_CONTRIBUTOR_ACCOUNTS_ADD_ERROR = 'GET_CONTRIBUTOR_ACCOUNTS_ADD_ERROR';

export const GET_CONTRIBUTOR_ACCOUNTS_EDIT_REQUEST = 'GET_CONTRIBUTOR_ACCOUNTS_EDIT_REQUEST';
export const GET_CONTRIBUTOR_ACCOUNTS_EDIT_SUCCESS = 'GET_CONTRIBUTOR_ACCOUNTS_EDIT_SUCCESS';
export const GET_CONTRIBUTOR_ACCOUNTS_EDIT_ERROR = 'GET_CONTRIBUTOR_ACCOUNTS_EDIT_ERROR';

export const GET_MASTER_ACCOUNTS_REQUEST = 'GET_MASTER_ACCOUNTS_REQUEST';
export const GET_MASTER_ACCOUNTS_SUCCESS = 'GET_MASTER_ACCOUNTS_SUCCESS';
export const GET_MASTER_ACCOUNTS_ERROR = 'GET_MASTER_ACCOUNTS_ERROR';

export const GET_COGNITO_USER_POOLS_REQUEST = 'GET_COGNITO_USER_POOLS_REQUEST';
export const GET_COGNITO_USER_POOLS_SUCCESS = 'GET_COGNITO_USER_POOLS_SUCCESS';
export const GET_COGNITO_USER_POOLS_ERROR = 'GET_COGNITO_USER_POOLS_ERROR';
