import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  ADD_ACCOUNT_ERROR,
  ADD_ACCOUNT_REQUEST,
  ADD_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_ERROR,
  EDIT_ACCOUNT_REQUEST,
  EDIT_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_STATUS_ERROR,
  EDIT_ACCOUNT_STATUS_REQUEST,
  EDIT_ACCOUNT_STATUS_SUCCESS,
  CREATE_AKAMAI_APP_REQUEST,
  CREATE_AKAMAI_APP_SUCCESS,
  CREATE_AKAMAI_APP_ERROR,
  SYNC_AKAMAI_APP_REQUEST,
  SYNC_AKAMAI_APP_SUCCESS,
  SYNC_AKAMAI_APP_ERROR,
  GET_ALL_ACCOUNTS_ERROR,
  GET_ALL_ACCOUNTS_REQUEST,
  GET_ALL_ACCOUNTS_SUCCESS,
  GET_STATE_ERROR,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
  SET_CANCEL_TOKEN,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_PLAN_ERROR,
  GET_SUBSCRIPTION_TYPE_REQUEST,
  GET_SUBSCRIPTION_TYPE_SUCCESS,
  GET_SUBSCRIPTION_TYPE_ERROR,
  GET_ACCOUNT_SETTINGS_REQUEST,
  GET_ACCOUNT_SETTINGS_SUCCESS,
  GET_ACCOUNT_SETTINGS_ERROR,
  EDIT_ACCOUNT_SETTINGS_REQUEST,
  EDIT_ACCOUNT_SETTINGS_SUCCESS,
  EDIT_ACCOUNT_SETTINGS_ERROR,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  GET_MASTER_ACCOUNTS_REQUEST,
  GET_MASTER_ACCOUNTS_SUCCESS,
  GET_MASTER_ACCOUNTS_ERROR,
  GET_COGNITO_USER_POOLS_REQUEST,
  GET_COGNITO_USER_POOLS_SUCCESS,
  GET_COGNITO_USER_POOLS_ERROR,
} from '../../actions/accountsActions/accounts';

const state = {
  status: '',
  states: [],
  subscriptionTypes: [],
  subscriptionPlans: [],
  cognitoUserPools: [],
  accounts: [],
  pagination: {},
  isLoading: false,
  source: null,
  masterAccounts: [],
};

const actions = {
  [ADD_ACCOUNT_REQUEST]: ({ commit }, { data }) => new Promise((resolve, reject) => {
    commit(ADD_ACCOUNT_REQUEST);

    apiCall.post('account', data)
      .then((resp) => {
        commit(ADD_ACCOUNT_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_ACCOUNT_ERROR, error);
        reject(error);
      });
  }),
  [GET_STATE_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_STATE_REQUEST);

    apiCall.get('choice/state')
      .then((resp) => {
        commit(GET_STATE_SUCCESS, resp.data.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_STATE_ERROR, error);
        reject(error);
      });
  }),
  [CREATE_AKAMAI_APP_REQUEST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(CREATE_AKAMAI_APP_REQUEST);

    apiCall.post(`account/${accountId}/akamai-app`)
      .then((resp) => {
        commit(CREATE_AKAMAI_APP_SUCCESS, resp);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_AKAMAI_APP_ERROR, error);
        reject(error);
      });
  }),
  [SYNC_AKAMAI_APP_REQUEST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(SYNC_AKAMAI_APP_REQUEST);

    apiCall.post(`akamai-app/${accountId}/sync`)
      .then((resp) => {
        commit(SYNC_AKAMAI_APP_SUCCESS, resp);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(SYNC_AKAMAI_APP_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_ACCOUNT_STATUS_REQUEST]:
  ({ commit }, { accountId, enabled }) => new Promise((resolve, reject) => {
    commit(EDIT_ACCOUNT_STATUS_REQUEST);

    apiCall.put(`account/status/${accountId}`, { enabled })
      .then((resp) => {
        commit(EDIT_ACCOUNT_STATUS_SUCCESS, resp);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_ACCOUNT_STATUS_ERROR, error);
        reject(error);
      });
  }),
  [GET_ALL_ACCOUNTS_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `account?p=${params.page}&l=15&filterField=a.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, {
      cancelToken: source.token,
    })
      .then((resp) => {
        commit(GET_ALL_ACCOUNTS_SUCCESS, resp.data);
        localStorage.setItem('currentPage', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_ALL_ACCOUNTS_ERROR);
      });
  }),
  [GET_ACCOUNT_REQUEST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(GET_ACCOUNT_REQUEST);

    apiCall.get(`account/${accountId}`)
      .then((resp) => {
        commit(GET_ACCOUNT_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ACCOUNT_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_ACCOUNT_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_ACCOUNT_REQUEST);

    apiCall.put(`account/${accountId}`, data)
      .then((resp) => {
        commit(EDIT_ACCOUNT_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_ACCOUNT_ERROR, error);
        reject(error);
      });
  }),
  [GET_SUBSCRIPTION_PLAN_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_SUBSCRIPTION_PLAN_REQUEST);

    apiCall.get('choice/subscription-plan')
      .then((resp) => {
        commit(GET_SUBSCRIPTION_PLAN_SUCCESS, resp.data.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_SUBSCRIPTION_PLAN_ERROR, error);
        reject(error);
      });
  }),
  [GET_SUBSCRIPTION_TYPE_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_SUBSCRIPTION_TYPE_REQUEST);

    apiCall.get('choice/subscription-type')
      .then((resp) => {
        commit(GET_SUBSCRIPTION_TYPE_SUCCESS, resp.data.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_SUBSCRIPTION_TYPE_ERROR, error);
        reject(error);
      });
  }),
  [GET_ACCOUNT_SETTINGS_REQUEST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(GET_ACCOUNT_SETTINGS_REQUEST);

    apiCall.get(`${accountId}/account-settings`)
      .then((resp) => {
        commit(GET_ACCOUNT_SETTINGS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ACCOUNT_SETTINGS_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_ACCOUNT_SETTINGS_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_ACCOUNT_SETTINGS_REQUEST);

    apiCall.put(`${accountId}/account-settings`, data)
      .then((resp) => {
        commit(EDIT_ACCOUNT_SETTINGS_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_ACCOUNT_SETTINGS_ERROR, error);
        reject(error);
      });
  }),
  [DELETE_ACCOUNT_REQUEST]:
    ({ commit }, { accountId, postfix, name }) => new Promise((resolve, reject) => {
      commit(DELETE_ACCOUNT_REQUEST);

      const url = `account/${accountId}?postfix=${postfix}&name=${name}`;

      apiCall.delete(url)
        .then(() => {
          commit(DELETE_ACCOUNT_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_ACCOUNT_ERROR, error);
          reject(error);
        });
    }),
  [GET_MASTER_ACCOUNTS_REQUEST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(GET_MASTER_ACCOUNTS_REQUEST);
    apiCall.get(`account/${accountId}/master`)
      .then((resp) => {
        commit(GET_MASTER_ACCOUNTS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_MASTER_ACCOUNTS_ERROR, error);
        reject(error);
      });
  }),
  [GET_COGNITO_USER_POOLS_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_COGNITO_USER_POOLS_REQUEST);

    apiCall.get('choice/user-provider/cognito/user-pool')
      .then((resp) => {
        commit(GET_COGNITO_USER_POOLS_SUCCESS, resp.data.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_COGNITO_USER_POOLS_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [ADD_ACCOUNT_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [GET_STATE_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [GET_ACCOUNT_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [GET_ACCOUNT_SUCCESS]: () => {
    state.isLoading = false;
    state.status = 'success';
  },
  [ADD_ACCOUNT_SUCCESS]: () => {
    state.isLoading = false;
    state.status = 'success';
  },
  [ADD_ACCOUNT_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
  // eslint-disable-next-line no-shadow
  [GET_STATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.states = payload;
    state.isLoading = false;
  },
  [EDIT_ACCOUNT_STATUS_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_ACCOUNT_STATUS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_ACCOUNT_STATUS_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_AKAMAI_APP_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_AKAMAI_APP_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_AKAMAI_APP_ERROR]: () => {
    state.isLoading = false;
  },
  [SYNC_AKAMAI_APP_REQUEST]: () => {
    state.isLoading = true;
  },
  [SYNC_AKAMAI_APP_SUCCESS]: () => {
    state.isLoading = false;
  },
  [SYNC_AKAMAI_APP_ERROR]: () => {
    state.isLoading = false;
  },
  // eslint-disable-next-line no-shadow
  [GET_ALL_ACCOUNTS_SUCCESS]: (state, payload) => {
    state.accounts = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  // eslint-disable-next-line no-shadow
  [EDIT_ACCOUNT_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_ACCOUNT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_ACCOUNT_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [GET_SUBSCRIPTION_TYPE_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [GET_SUBSCRIPTION_TYPE_SUCCESS]: (state, types) => {
    state.status = 'success';
    state.subscriptionTypes = types;
    state.isLoading = false;
  },
  [GET_SUBSCRIPTION_TYPE_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
  [GET_SUBSCRIPTION_PLAN_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [GET_SUBSCRIPTION_PLAN_SUCCESS]: (state, plans) => {
    state.status = 'success';
    state.subscriptionPlans = plans;
    state.isLoading = false;
  },
  [GET_SUBSCRIPTION_PLAN_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
  [GET_ALL_ACCOUNTS_ERROR]: () => {
    state.status = 'error';
    state.isLoading = false;
  },
  [EDIT_ACCOUNT_SETTINGS_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_ACCOUNT_SETTINGS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_ACCOUNT_SETTINGS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ACCOUNT_SETTINGS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_ACCOUNT_SETTINGS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_ACCOUNT_SETTINGS_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_ACCOUNT_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_ACCOUNT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_ACCOUNT_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_MASTER_ACCOUNTS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MASTER_ACCOUNTS_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.masterAccounts = payload.data;
  },
  [GET_MASTER_ACCOUNTS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_COGNITO_USER_POOLS_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [GET_COGNITO_USER_POOLS_SUCCESS]: (state, pools) => {
    state.status = 'success';
    state.cognitoUserPools = pools.map((pool) => ({ code: pool.id, name: pool.name }));
    state.isLoading = false;
  },
  [GET_COGNITO_USER_POOLS_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
};

export default {
  state,
  actions,
  mutations,
};
